import axios from 'axios';

const api_url = process.env.VUE_APP_API_URL;

axios.defaults.headers.common['X-TOKEN'] = `${localStorage.getItem('API_TOKEN')}`;

/**
 * Se elegió el nombre pack en vez de package por ser una palabra reservada
 */

const store = {
    namespaced: true,
    state: {
        packs: [], // paquetes cargados
        loading: false,
    },
    getters: {
        packs: state => state.packs,
        loading: state => state.loading,
    },
    mutations: {
        add(state, pack) {
            state.packs = [...state.packs, pack];
        },
        clear(state) {
            state.packs = [];
        },
        setLoading(state, loading) {
            state.loading = loading;
        },
        setError(state, error) {
            state.error = error;
        }
    },
    actions: {
        assign({ commit }, data) {
            commit('setLoading', true);
            return new Promise((resolve, reject)=> {
                axios.put(`${api_url}/paquetes/tomar`, data)
                    .then(response => {
                        if (response.data && response.data.id) {
                            commit('add', response.data);
                        }
                        resolve(response)
                    })
                    .catch(error => reject(error))
                    .finally(() => {
                        commit('setLoading', false);
                    })
                ;
            })
        },
        clear({ commit }) {
            commit('clear');
        }
    },
};

export default store;