import { createStore } from 'vuex';

// Modules
import auth from './modules/auth';
import packs from './modules/packs';
import qr from './modules/qr';
import history from './modules/history';
import movements from './modules/movements';
import ui from './modules/ui';

export default createStore({
    modules: {
        auth,
        packs,
        qr,
        history,
        movements,
        ui,
    },    
    strict: true
});