export default [
    {
        path: '/',
        redirect: '/packages',
    },
    {
        path: '/packages',
        name: 'packs.index',
        component: () => import('../pages/packs/IndexPage.vue'),
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/packages/:id',
        name: 'packs.show',
        component: () => import('../pages/packs/ShowPage.vue'),
        meta: {
            requiresAuth: true,
        }
    },
    {
        name: 'packs.mark-as-not-delivered',
        path: '/packages/:id/mark-as-not-delivered',
        component: () => import('../pages/packs/MarkAsNotDeliveredPage.vue'),
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/packages/qr',
        name: 'packs.qr',
        component: () => import('../pages/packs/QRPage.vue'),
        meta: {
            requiresAuth: true,
        }
    },
];