import axios from 'axios';
import kernel from '../../middlewares';
kernel.apply('auth', axios);
kernel.apply('ForbiddenChecker', axios);
kernel.apply('laravelValidation', axios);

const api_url = process.env.VUE_APP_API_URL;

/**
 * Se elegió el nombre pack en vez de package por ser una palabra reservada
 */

const store = {
    namespaced: true,
    state: {
        packs: [],
        pack: null,
        loading: false,
    },
    getters: {
        packs: state => state.packs,
        pack: state => state.pack,
        loading: state => state.loading,
    },
    mutations: {
        setpacks(state, packs) {
            state.packs = packs;
        },
        setpack(state, pack) {
            state.pack = pack;
        },
        addpack(state, pack) {
            pack.id = new Date().toISOString();
            state.packs.push(pack);
        },
        setLoading(state, loading) {
            state.loading = loading;
        }
    },
    actions: {
        index({ commit }) {
            commit('setLoading', true);

            axios.get(`${api_url}/paquetes`)
                .then(response => {
                    commit('setpacks', response.data);
                })
                .catch(error => {
                    console.log(error);
                })
                .finally(() => {
                    commit('setLoading', false);
                })
            ;
        },
        show({ commit }, id) {
            axios.get(`${api_url}/historicos/paquetes/${id}`)
                .then(response => {
                    commit('setpack', response.data);
                }).catch(error => {
                    console.log(error);
                })
            ;
        },
        reset({ commit }) {
            commit('setpack', null);
        },
        markAsDelivered({ commit, dispatch }, id) {
            commit('setLoading', true);
            axios.put(`${api_url}/paquetes/entregado`, { id })
                .then(response => {
                    commit('setpack', response.data);
                    dispatch('index');
                }).catch(error => {
                    console.log(error);
                }).finally(() => {
                    commit('setLoading', false);
                })
            ;
        },
        markAsNotDelivered({ commit, dispatch }, data) {
            commit('setLoading', true);
            axios.post(`${api_url}/paquetes/no-entregado`, data)
                .then(response => {
                    commit('setpack', response.data);
                    dispatch('index');
                    
                }).catch(error => {
                    console.log(error);
                }).finally(() => {
                    commit('setLoading', false);
                })
            ;
        },
        addpack({ commit }, pack) {
            commit('addpack', pack);
        },
        readLabel(_, label) {
            axios.post('http://127.0.0.1:8000/api/labels/read/', {
                label: label
            }).then(response => {
                alert(response.data);
            }).catch(error => {
                console.log(error);
            });
        }
    },
};

export default store;