<template>
    <ion-page>
        <!-- header -->
        <!-- <app-header :page-title="pageTitle"></app-header> -->
        <!-- /header -->
        
        <!-- content -->
        <ion-content id="content">
            <slot />
        </ion-content>
        <!-- /content -->
    </ion-page>
</template>

<script>
import { 
    IonPage, 
    IonContent,
} from '@ionic/vue';

// import AppHeader from '../components/app/AppHeader.vue';

export default {
    components: {
        IonPage,
        IonContent,
        // AppHeader
    },

    props: {
        pageTitle: {
            type: String,
            default: ''
        }
    }
}
</script>