import axios from 'axios';
import dayjs from 'dayjs';
const api_url = process.env.VUE_APP_API_URL;

axios.defaults.headers.common['X-TOKEN'] = `${localStorage.getItem('API_TOKEN')}`;

const store = {
    namespaced: true,
    state: {
        movements: [],
        loading: false,
        page: 0,
        lastPage: 1000,
        period: 'month',
        order: 'fecha_desc',
        totalValor: 0,
        totalEnvios: 0,
    },
    getters: {
        movements: state => state.movements,
        loading: state => state.loading,
        page: state => state.page,
        lastPage: state => state.lastPage,
        period: state => state.period,
        totalValor: state => state.totalValor,
        totalEnvios: state => state.totalEnvios,
        order: state => state.order,
    },
    mutations: {
        setMovements(state, movements) {
            state.movements = movements;
        },
        appendMovements(state, movements) {
            state.movements = state.movements.concat(movements);
        },
        setLoading(state, loading) {
            state.loading = loading;
        },
        setPage(state, page) {
            state.page = page;
        },
        setValorTotal(state, totalValor) {
            state.totalValor = totalValor;
        },
        setTotalEnvios(state, totalEnvios) {
            state.totalEnvios = totalEnvios;
        },
        setLastPage(state, lastPage) {
            state.lastPage = lastPage;
        },
        setPeriod(state, period) {
            state.period = period;
        },
        reset(state, data) {
            state.page = 0;
            state.lastPage = 1000;
            state.movements = [];
            state.period = data.period || state.period;
            state.order = data.order || state.order;
        }
    },
    actions: {
        index({ commit, state }, payload) {
            commit('setLoading', true);

            const period = state.period == 'month' 
                ? {fecha_desde: dayjs().startOf('month').format('YYYY-MM-DD'), fecha_hasta: dayjs().format('YYYY-MM-DD')} 
                : {fecha_desde: dayjs().subtract(state.period, 'days').format('YYYY-MM-DD'), fecha_hasta: dayjs().format('YYYY-MM-DD')};
            
            const page = state.page + 1;
            
            axios.get(`${api_url}/cuenta-corriente`, {
                    params: {
                        page,
                        orden: state.order,
                        ...period
                    }
                })
                .then(response => {
                    commit('setLastPage', response.data.last_page);
                    commit('setValorTotal', response.data.totalValor);
                    commit('setTotalEnvios', response.data.totalEnvios);
                    commit('setPage', page);
                    const mutations = payload.append ? 'appendMovements' : 'setMovements';
                    commit(mutations, response.data.data);
                })
                .catch(error => {
                    console.log(error);
                })
                .finally(() => {
                    commit('setLoading', false);
                })
            ;
        },
        filterChanged({ commit, dispatch }, payload) {
            commit('reset', payload);
            dispatch('index');
        }
    },
};

export default store;