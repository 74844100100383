import axios from 'axios';
import dayjs from 'dayjs';
const api_url = process.env.VUE_APP_API_URL;

axios.defaults.headers.common['X-TOKEN'] = `${localStorage.getItem('API_TOKEN')}`;

const store = {
    namespaced: true,
    state: {
        packs: [],
        loading: false,
        page: 0,
        lastPage: 1000,
        estado: 'all',
        periodo: '30',
    },
    getters: {
        packs: state => state.packs,
        loading: state => state.loading,
        page: state => state.page,
        lastPage: state => state.lastPage,
        estado: state => state.estado,
        periodo: state => state.periodo,
    },
    mutations: {
        setPacks(state, packs) {
            state.packs = packs;
        },
        appendPacks(state, packs) {
            state.packs = state.packs.concat(packs);
        },
        setLoading(state, loading) {
            state.loading = loading;
        },
        setPage(state, page) {
            state.page = page;
        },
        setLastPage(state, lastPage) {
            state.lastPage = lastPage;
        },
        setEstado(state, estado) {
            state.estado = estado;
        },
        setPeriodo(state, periodo) {
            state.periodo = periodo;
        }
    },
    actions: {
        index({ commit, state }, payload) {
            commit('setLoading', true);

            const estados = state.estado == 'all' ? '20,30,40,50' : state.estado;
            const periodo = state.periodo == 'all' ? {} : {fecha_desde: dayjs().subtract(state.periodo, 'days').format('YYYY-MM-DD'), fecha_hasta: dayjs().format('YYYY-MM-DD')};
            // const periodo = state.periodo == 'all' ? {} : {fecha_desde: '2022-02-02', fecha_hasta: '2022-02-02'};

            const page = state.page + 1;

            axios.get(`${api_url}/historicos/paquetes`, {
                    params: {
                        page,
                        estados,
                        ...periodo
                    }
                }).then(response => {
                    commit('setPage', page);
                    commit('setLastPage', response.data.last_page);
                    const action = payload.append ? 'appendPacks' : 'setPacks';
                    commit(action, response.data.data);
                })
                .catch(error => console.log(error))
                .finally(() => commit('setLoading', false))
            ;
        },
        filterChanged({ dispatch }) {
            dispatch('index');
        }
    },
};

export default store;