import axios from 'axios';
import router from '../../router';
import ApiToken from '../../utils/auth';

const api_url = process.env.VUE_APP_API_URL;

export default {
    namespaced: true,
    state: {
        user: null,
        isLoading: false,
    },
    getters: {
        user: state => state.user,
        isLoading: state => state.isLoading,
    },
    mutations: {
        setUser(state, user) {
            state.user = user;
        },
        setLoading(state, isLoading) {
            state.isLoading = isLoading;
        },
    },
    actions: {
        login({ commit }, payload) {
            commit('setLoading', true);

            axios.post(`${api_url}/login`, { email: payload.email, password: payload.password }).then(response => {
                commit('setUser', response.data);
                
                ApiToken.set(response.data.token);

                router.push('/');
            }).finally(() => {
                commit('setLoading', false);
            });
        },
        logout({ commit }) {
            commit('setUser', null);
        
            ApiToken.refresh();
        }
    }
}
